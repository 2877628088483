import { Controller } from '../Controller';
import {
  I$WWrapper,
  IWixAPI,
} from '@wix/native-components-infra/dist/src/types/types';
import { ApiTypesV1GroupResponse, isMember } from '@wix/social-groups-api';
import {
  initializeCommentsController,
  CommentsControllerApi,
  PaginationState,
} from '@wix/comments-ooi-client/controller';

import { ICommentsProps } from './ICommentsProps';
import { PubSubObserver } from '../pubSub/PubSubObserver';
import { PubSubEventTypes } from '../pubSub/PubSubEventTypes';
import { isMemberCommentAction } from './isMemberCommentAction';
import { ControllerParams } from '@wix/yoshi-flow-editor';

const COMMENTS_TRANSLATIONS = 'comments_translations';
export class CommentsController
  extends Controller<ICommentsProps>
  implements PubSubObserver
{
  private pendingJoinRequest!: {
    resolve: (value?: unknown) => void;
    reject: (reason?: any) => void;
  };

  private commentsApi!: CommentsControllerApi;

  constructor(
    controllerContext: ControllerParams,
    private group: ApiTypesV1GroupResponse,
  ) {
    super(controllerContext, group.groupId!);
    this.setSubscriptions();
  }

  pageReady(
    $w: I$WWrapper | undefined,
    wixAPI: IWixAPI | undefined,
  ): Promise<any> {
    if (!this.isSSR()) {
      this.initComments().catch((e) =>
        console.log('Error in [CommentsController.pageReady:initComments]', e),
      );
    }
    this.setState({
      refuseToJoin: this.refuseToJoin,
      commentsTranslations: this.getCommentsTranslations(),
    });
    return Promise.resolve(undefined);
  }

  private async initComments() {
    // TODO: [YO] comments experiment
    const appDefinitionId = this.getAppDefinitionId();
    const subscription = (next: any) => (action: any) => {
      if (!this.getTranslations()) {
        const commentsTranslations = action.payload;
        if (
          action.type === 'FETCH_TRANSLATIONS_SUCCESS' &&
          commentsTranslations
        ) {
          this.saveTranslations(commentsTranslations);
          this.setState({ commentsTranslations });
        }
      }
      next(action);
    };
    const commentsInteractions = {
      appDefinitionId,
      actionPreconditions: {
        isPreconditionRequiredForAction: (action: any) => {
          if (isMemberCommentAction(action)) {
            return !isMember(this.group);
          }
          return false;
        },
        preconditionCallback: async (action: any) => {
          await new Promise((resolve, reject) => {
            this.setState({ requestToJoin: true });
            this.pendingJoinRequest = {
              resolve: () => {
                this.pendingJoinRequest = null as any;
                resolve(undefined);
              },
              reject: () => {
                this.pendingJoinRequest = null as any;
                reject(new Error('user refused'));
              },
            };
          }).finally(() => {
            this.setState({ requestToJoin: false });
          });
        },
      },
      subscribe: [subscription],
      httpClient: this.getHTTPClient(),
    };
    this.commentsApi = await initializeCommentsController(
      this.controllerConfig,
      commentsInteractions,
    );

    this.commentsApi.watch.pagination.onChange((paginationState) => {
      this.publishCommentsChanged(paginationState);
    });
  }

  setSubscriptions() {}
  private readonly onDidUpdateGroup = async ({ data }: any) => {
    try {
      const updatedGroup = JSON.parse(data);
      this.group = updatedGroup;
    } catch (e) {
      console.log('[CommentsController.onDidUpdateGroup] Error');
    }
  };

  removeSubscriptions() {}

  private publishCommentsChanged(action: PaginationState) {
    try {
      this.controllerConfig.platformAPIs.pubSub.publish(
        PubSubEventTypes.COMMENTS_CHANGED,
        action,
        false,
      );
    } catch (e) {
      console.log('[CommentsController.publishCommentCreated] Error');
    }
  }

  private refuseToJoin = () => {
    if (this.pendingJoinRequest) {
      this.pendingJoinRequest.reject();
    }
    this.setState({ requestToJoin: false });
  };

  private updateContextTokens = (): void => {
    if (this.pendingJoinRequest) {
      this.pendingJoinRequest.resolve();
    } else {
      this.commentsApi.permissionsChangedExternally();
    }
  };
  private saveTranslations(commentsTranslations: object): void {
    this.getMemoryStorage().setItem(
      COMMENTS_TRANSLATIONS,
      JSON.stringify(commentsTranslations),
    );
  }

  private getCommentsTranslations() {
    try {
      const commentsTranslations = this.getMemoryStorage().getItem(
        COMMENTS_TRANSLATIONS,
      );
      return JSON.parse(commentsTranslations!);
    } catch (e) {
      console.log(
        '[CommentsController.getTranslations] Error getting translations',
      );
    }
  }
}

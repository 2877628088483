import {
  IControllerConfig,
  IPublicData,
} from '@wix/native-components-infra/dist/src/types/types';

import Api, {
  ApiTypesV1GroupDetails,
  ApiTypesV1GroupResponse,
  ApiTypesV1GroupSettings,
  AppToastTypes,
  canSeeGroup,
  Group,
  isPendingGroup,
  isGroupPrivate,
  MediaApi,
  VideoMetadataApi,
} from '@wix/social-groups-api';
import { GroupApp } from '@wix/ambassador-social-groups-v1-group-app/types';
import { Controller } from '../Controller';
import { GroupControllerProps } from './GroupControllerProps';
import { PubSubObserver } from '../pubSub/PubSubObserver';
import { PubSubEventTypes } from '../pubSub/PubSubEventTypes';
import {
  AppToastsController,
  BaseControllerContext,
  Tab,
} from '../../../../common/controllers';
import { Button } from '../../types/button';
import { UpdateProgress } from '../../../../common/ContentEditor/UpdateProgress';
import { getRCApiBaseUrl } from '../../../../common/utils/baseUrl';
import { ConsoleLogger } from '../../../../common/loggers';
import { createEventHandler } from '@wix/yoshi-flow-editor/tpa-settings';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { IGroupActions } from '../../contexts/GroupActions/IGroupActions';
import { UserRequestResponse } from '../../../../common/context/user/IUserContext';
import { LeaveRequest } from '@wix/ambassador-social-groups-v2-group-member/types';
import { SettingsEventsTypes } from './SettingsEventsTypes';

enum MemberActionType {
  LEAVE = 'leave',
  JOIN = 'join',
  WITHDRAW = 'withdraw',
}

export interface SettingsEvents {
  [SettingsEventsTypes.activeTab]: Tab;
  [SettingsEventsTypes.activeButton]: Button;
}

export class GroupController
  extends Controller<GroupControllerProps>
  implements PubSubObserver
{
  private apiClient!: Api;
  private groupModel!: Group;
  private mediaApi!: MediaApi;
  private videoMetadataApi!: VideoMetadataApi;
  private pendingAction!: MemberActionType;
  private readonly subscriptions: Map<number, PubSubEventTypes> = new Map();
  private handler!: any;

  constructor(
    controllerContext: ControllerParams,
    private group: ApiTypesV1GroupResponse,
  ) {
    super(controllerContext, group.groupId!);
    this.initExternalServices(this.getSiteToken()!, group.groupId!);
    this.getLocation().onChange(this.handleLocationChange);
    this.onUserLogin(async () => {
      const id = await this.getGroupId();
      this.initExternalServices(this.getSiteToken()!, id!);
      await this.setGroup();
      this.resetUpdatesCounter();
    });
    this.setSubscriptions();
    this.subscribeToPublicData(this.controllerConfig.config.publicData);
  }

  pageReady = async () => {
    this.setState({
      changeTab: this.changeTab, // TODO: why twice?
      actions: this.getActions(),
    });

    if (isGroupPrivate(this.group)) {
      this.controllerConfig.wixCodeApi.seo.setSeoStatusCode(403);
    }

    this.resetUpdatesCounter();
    return;
  };

  updateConfig($w: any, config: any) {
    this.notifyPublicDataChanged(config);
  }

  private readonly getActions = (): IGroupActions => ({
    changeTab: this.changeTab, // TODO: why twice?
    getExternalVideoMetadata: this.getVideoMetadata,
    uploadFiles: this.uploadFiles,
    updateGroup: this.updateGroup,
    configureApps: this.configureApps,
    setEditMode: this.setEditMode,
    deleteGroup: this.deleteGroup,
    goToGroupList: this.goToGroupList,
    inviteMembersByEmail: this.inviteMembersByEmail, // TODO: can we move it to MembersController?
    fetchGroupRules: this.fetchGroupRules.bind(this),
    // saveMembershipQuestions: this.saveMembershipQuestions,
  });

  async getInitialProps(): Promise<Partial<GroupControllerProps>> {
    const { activeTab, feedItemId } = await this.getUrlSegments();

    const initialProps: Partial<GroupControllerProps> = {
      locale: this.getLocale(),
      cssBaseUrl: this.getCssBaseUrl(),
      activeTab,
      activeButton: Button.PRIMARY,
      feedItemId,
      uploadedRegistry: [],
      externalVideosMetadataRegistry: [],
      updateProgress: UpdateProgress.STALE,
      group: this.group,
      loading: false,
    };
    try {
      initialProps.apps = await this.groupModel.getApps();
    } catch (e) {
      console.log(
        'GroupController.getInitialProps: FAILED to fetch GroupApps',
        e,
      );
      this.errorLogger.log(e);
    }
    this.state = initialProps;
    return Promise.resolve(initialProps);
  }

  private readonly uploadFiles = async (filename: string, file: File) => {
    this.setState({ updateProgress: UpdateProgress.UPDATING });
    const uploadFile = { filename, uploadResponse: null, error: null } as any;
    try {
      if (file) {
        const { data } = await this.mediaApi.uploadFile(file);
        uploadFile.uploadResponse = data;
      } else {
        uploadFile.uploadResponse = [null];
      }
    } catch (e: any) {
      uploadFile.error = (e && e.response && e.response.data) || e;
      this.errorLogger.log(e);
    } finally {
      this.setState({
        uploadedRegistry: [uploadFile],
        updateProgress: UpdateProgress.STALE,
      });
    }
  };

  private readonly getVideoMetadata = async (videoUrl: string) => {
    let updatedRegistry = [...this.state.externalVideosMetadataRegistry!];
    const prevResIndex = updatedRegistry.findIndex((metadata) => {
      return metadata.videoUrl === videoUrl;
    });

    this.setState({ updateProgress: UpdateProgress.UPDATING });

    // does not have previous result in registry for the videoUrl
    if (prevResIndex === -1) {
      const metadataResponse = { videoUrl, metadata: null, error: null } as any;

      try {
        const { data } = await this.videoMetadataApi.getVideoMetadata(videoUrl);
        metadataResponse.metadata = data;
      } catch (e: any) {
        metadataResponse.error = e.response.data;
        this.errorLogger.log(e);
      } finally {
        updatedRegistry = updatedRegistry.concat([metadataResponse]);
      }
    }

    this.setState({
      externalVideosMetadataRegistry: updatedRegistry,
      updateProgress: UpdateProgress.STALE,
    });
  };

  setState(props: Partial<GroupControllerProps>) {
    this.state = { ...this.state, ...props };
    this.controllerConfig.setProps(props);
  }

  async fetchGroupRules() {
    const rules = await this.groupModel.getRules();

    this.setState({ rules });
  }

  handleLocationChange = ({ path }: { path: string[] }) => {
    this.getUrlSegments()
      .then(({ activeTab, feedItemId }) => {
        this.setState({
          activeTab,
          feedItemId,
        });
      })
      .catch((e) => {
        console.log('[GroupController.handleLocationChange] Failed', e);
        this.errorLogger.log(e);
      });
  };

  changeTab = async (tab: Tab) => {
    if (this.isEditorMode()) {
      return this.setState({ activeTab: tab });
    }
    const location = this.getLocation();

    try {
      const groupUrl = await this.getGroupUrl({
        groupId: this.group.slug!,
        tabName: tab,
      });
      const _url = groupUrl.replace(location.baseUrl, ''); // absolute urls reloads page
      location.to!(`${_url}#preventScrollToTop`);
    } catch (e) {
      console.log('[GroupController.changeTab] Failed');
      this.errorLogger.log(e);
    }
  };

  changeActiveButton = (button: Button) => {
    this.setState({ activeButton: button });
  };

  private getCssBaseUrl() {
    const { staticsGroupBaseUrl, staticsBaseUrl } =
      this.controllerConfig.appParams.baseUrls;
    return staticsGroupBaseUrl || staticsBaseUrl;
  }

  private initExternalServices(instance: string, groupId: string) {
    this.apiClient = new Api(
      instance,
      this.getApiBaseUrl(),
      new BaseControllerContext(this.controllerContext),
    );
    this.mediaApi = new MediaApi(this.apiClient);
    const location = this.getLocation();
    this.videoMetadataApi = new VideoMetadataApi(
      instance,
      getRCApiBaseUrl(location.baseUrl),
    );
    this.groupModel = new Group(
      groupId,
      this.apiClient,
      this.controllerConfig.platformAPIs,
    );
  }

  onBeforeUnLoad() {
    this.removeSubscriptions();
  }

  removeSubscriptions() {
    // https://wix.slack.com/archives/CAKBA7TDH/p1601480311035300
    // TODO: remove after fixed on TB side

    try {
      const storage = this.controllerConfig.platformAPIs.storage.session;
      const key = 'GroupController.setSubscriptions';
      const subscr = JSON.parse(storage.getItem(key)!);
      if (!subscr) {
        return;
      }
      for (const [id, event] of Object.entries(subscr) as any) {
        this.controllerConfig.platformAPIs.pubSub.unsubscribe(event, id);
      }
      storage.removeItem(key);
    } catch (e) {
      console.log('Error in GroupController.removeSubscriptions');
    }
    //
    try {
      this.subscriptions.forEach((event, subscrId) => {
        this.controllerConfig.platformAPIs.pubSub.unsubscribe(event, subscrId);
      });
    } catch (e) {
      ConsoleLogger.log(e);
    } finally {
      this.subscriptions.clear();
    }
  }

  setSubscriptions() {
    try {
      this.controllerConfig.platformAPIs.pubSub.subscribe(
        PubSubEventTypes.UPDATE_GROUP_EVENT,
        () => {
          return this.setGroup();
        },
        false,
      );

      this.controllerConfig.platformAPIs.pubSub.subscribe(
        PubSubEventTypes.WILL_UPDATE_GROUP_EVENT,
        () => {
          this.setState({ updateProgress: UpdateProgress.STARTED });
        },
        false,
      );
      this.subscribe<UserRequestResponse>(
        PubSubEventTypes.JOIN_GROUP,
        ({ data }) => {
          return this.setGroup();
        },
      );
      this.subscribe<LeaveRequest>(PubSubEventTypes.LEAVE_GROUP, ({ data }) => {
        return this.setGroup();
      });
    } catch (e) {
      ConsoleLogger.log(e);
      this.errorLogger.log(e);
    }
  }

  subscribeToPublicData(publicData: IPublicData) {
    const data = publicData.COMPONENT || {};
    const handler = createEventHandler<SettingsEvents>(data);

    handler.on(SettingsEventsTypes.activeTab, (value: Tab) => {
      this.changeTab(value);
    });
    handler.on(SettingsEventsTypes.activeButton, (value: Button) => {
      this.changeActiveButton(value);
    });

    handler.onReset(() => {
      this.changeTab(Tab.DISCUSSION);
      this.changeActiveButton(Button.PRIMARY);
    });

    this.handler = handler;
  }

  notifyPublicDataChanged(config: IControllerConfig) {
    this.handler.notify(config.publicData.COMPONENT || {});
  }

  private readonly setGroup = async () => {
    try {
      const group = await this.groupModel.fetch();
      this.group = group;
      this.publishDidUpdateGroup(group);
      this.setState({
        group,
        updateProgress: UpdateProgress.STALE,
      });
    } catch (e) {
      this.errorLogger.log(e);
    }
  };

  private publishDidUpdateGroup(group: ApiTypesV1GroupResponse) {
    try {
      this.controllerConfig.platformAPIs.pubSub.publish(
        PubSubEventTypes.DID_UPDATE_GROUP_EVENT,
        JSON.stringify(group),
        false,
      );
    } catch (e) {
      ConsoleLogger.log(e);
      this.errorLogger.log(e);
    }
  }

  private readonly setEditMode = (updateProgress: UpdateProgress) => {
    this.setState({ updateProgress });
  };

  private readonly updateGroup = async (
    paths: string[],
    details?: ApiTypesV1GroupDetails,
    settings?: ApiTypesV1GroupSettings,
  ) => {
    this.setState({ updateProgress: UpdateProgress.UPDATING });
    this.groupModel.updateGroup(paths, details, settings);
    const group = await this.groupModel.update();
    this.onGroupUpdated(this.group, group);
  };

  private onGroupUpdated(
    group: ApiTypesV1GroupResponse,
    updatedGroup: ApiTypesV1GroupResponse,
  ) {
    this.group = updatedGroup;
    this.setState({ group: this.group, updateProgress: UpdateProgress.STALE });
    this.maybeRedirect(updatedGroup.slug!, group.slug!).catch((e) => {
      console.log('[GroupController.onGroupUpdated] Failed', e);
      this.errorLogger.log(e);
    });
  }

  private async maybeRedirect(slug: string, prevSlug: string) {
    // slug changed
    try {
      if (slug && prevSlug !== slug) {
        const location = this.getLocation();
        const url = await this.getGroupUrl({
          groupId: slug,
          tabName: Tab.DISCUSSION,
        });
        location.to!(url);
      }
    } catch (e) {
      console.log('[GroupController.maybeRedirect] Error');
      this.errorLogger.log(e);
    }
  }

  private async getGroupApps() {
    const apps = await this.groupModel.getApps();
    this.setState({ apps });
  }

  private readonly configureApps = async (apps: GroupApp[]) => {
    this.setState({ updateProgress: UpdateProgress.UPDATING });
    const updatedApps = await this.groupModel.configureApps(apps);
    this.setState({ updateProgress: UpdateProgress.STALE, apps: updatedApps });
  };

  private readonly inviteMembersByEmail = (emails: string[]) => {
    return this.groupModel.inviteMembersByEmail(emails);
  };

  private readonly deleteGroup = async () => {
    try {
      const groupName = this.groupModel.getTitle();
      await this.groupModel.delete();
      AppToastsController.publish(this.controllerConfig.platformAPIs.pubSub, {
        type: AppToastTypes.GROUP_DELETED,
        options: {
          'group-name': groupName,
        },
      });
      const location = this.getLocation();
      location.to!('/groups'); // TODO: [YO] Groups url can be different!!
    } catch (e) {
      console.error('Delete Group failed');
      this.errorLogger.log(e);
    }
  };

  resetUpdatesCounter() {
    if (
      this.isUserLoggedIn() &&
      canSeeGroup(this.group) &&
      !isPendingGroup(this.group)
    ) {
      this._api
        .getGroupsAppSettings()
        .resetUpdatesCounter({
          groupId: this.group.groupId!,
        })
        .catch((e) => {
          console.log('FAIL: resetUpdatesCounter', e);
        });
    }
  }
}

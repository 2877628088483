import { autorun } from 'mobx';
import { serialize } from 'serializr';
import { identity, pick, pickBy, flatten, uniq } from 'lodash';
import {
  ApiTypesV1GroupResponse,
  canSeeFeed,
  RequestState,
  SocialContentService,
  toQueryParams,
} from '@wix/social-groups-api';
import { DEFAULT_FEED_LIMIT, FeedStore } from './store/FeedStore';
import { Controller } from '../Controller';
import { PubSubObserver } from '../pubSub/PubSubObserver';
import { PubSubEventTypes } from '../pubSub/PubSubEventTypes';

import * as IFeedTypes from '@wix/ambassador-feed-v1-feed-item/types';
import * as IReactionsTypes from '@wix/ambassador-reactions-v1-reaction/types';

import { Duplexer } from '@wix/duplexer-js';
import Channel from '@wix/duplexer-js/dist/src/channels/channel';

import { ErrorOrigin } from '../errorHandler/IErrorEvent';
import { ControllerError } from '../errorHandler/ControllerError';
import {
  FeedControllerProps,
  Filters,
  IFeedActions,
} from '../../types/FeedControllerProps';
import { CONTEXT_CALLBACKS_APP_ID } from '../../../../config/constants';
import { ConsoleLogger } from '../../../../common/loggers/ConsoleLogger';
import { Tab } from '../../../../common/controllers/group-url/Tab';
import { EFilterKeys } from '../../types/EFilterKeys';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { PaginationState } from '@wix/comments-ooi-client/controller';
import { LeaveRequest } from '@wix/ambassador-social-groups-v2-group-member/types';
import { BaseControllerContext } from 'common/controllers';

const FIRST_LOAD_FEED_ITEMS_COUNT = 2;

export const FILTER_KEYS_WHITELIST = Object.values(EFilterKeys);

export class FeedController
  extends Controller<FeedControllerProps>
  implements PubSubObserver
{
  feedFilters: Partial<Filters> = {};
  feedStore!: FeedStore;
  feedRepository!: SocialContentService;

  private duplexer!: Duplexer;
  private feedChannel!: Channel;
  private readonly subscriptions: Map<number, PubSubEventTypes> = new Map();

  constructor(
    controllerContext: ControllerParams,
    private group: ApiTypesV1GroupResponse,
  ) {
    super(controllerContext, group.groupId!);
    this.setSubscriptions();
    this.onUserLogin(this.handleUserLogin);
    this.getLocation().onChange(this.handleLocationChange);
  }

  handleUserLogin = () => {
    return this.initFeed();
  };

  private installDuplexer(groupId: string) {
    const { appParams } = this.controllerConfig;

    this.duplexer = new Duplexer('duplexer.wix.com', {
      instanceUpdater: {
        getInstance: this.getSiteToken.bind(this) as any,
      },
    });

    const socket = this.duplexer.connect({
      appDefId: CONTEXT_CALLBACKS_APP_ID,
    });

    this.feedChannel = socket.subscribe('wix-feed_callbacks', {
      resourceId: `${appParams.appDefinitionId}_${groupId}`,
    });

    socket.on('@duplexer:connect_error', (error: any) => {
      console.error('@duplexer:connect_error: ', error);
      socket.disconnect();
    });
  }

  private setFeedStore(groupId: string) {
    this.feedRepository = new SocialContentService(
      groupId,
      new BaseControllerContext(this.controllerContext),
    );

    if (this.feedStore) {
      this.feedStore.repository = this.feedRepository;
      this.feedStore.channel = this.feedChannel;
    } else {
      this.feedStore = new FeedStore(
        this.feedRepository,
        groupId,
        this.flowAPI.httpClient,
      );
    }
  }

  private async initFeed() {
    try {
      const groupId = await this.getGroupId();

      this.setFeedStore(groupId!);
      return this.resolveRoute();
    } catch (e) {
      ConsoleLogger.log('FeedController.setFeed', e);
      this.errorLogger.log(e);
    }
  }

  getActions(): IFeedActions {
    return {
      fetchMore: this.fetchMore,
      deleteFeedItem: this.deleteFeedItem,
      createFeedItem: this.createFeedItem,
      updateFeedItem: this.updateFeedItem,
      reactFeedItem: this.addReaction,
      unreactFeedItem: this.removeReaction,
      createPostTopic: this.createPostTopic,
      applyFeedFilters: this.applyFilters,
      pinFeedItem: this.feedStore.pin.bind(this.feedStore),
      unpinFeedItem: this.feedStore.unpin.bind(this.feedStore),
      followFeedItem: this.feedStore.follow.bind(this.feedStore),
      unfollowFeedItem: this.feedStore.unfollow.bind(this.feedStore),
    };
  }

  private getUserReaction(reaction: IReactionsTypes.Reaction) {
    const userReaction: IReactionsTypes.UserReaction = {
      reaction,
      userId: this.getCurrentUser().id,
    };
    return userReaction;
  }

  private readonly addReaction = (
    feedItemId: string,
    reaction: IReactionsTypes.Reaction,
  ) => {
    const userReaction = this.getUserReaction(reaction);
    this.feedStore.addReaction(feedItemId, userReaction);
  };

  private readonly removeReaction = (
    feedItemId: string,
    reactionCode: string,
  ) => {
    const userReaction = this.getUserReaction({ reactionCode });
    this.feedStore.removeReaction(feedItemId, userReaction);
  };

  createFeedItem = async (entity: IFeedTypes.FeedItemEntity) => {
    this.setProps({ feedRequest: { createPost: RequestState.PENDING } });
    try {
      await this.feedStore.create(entity);
      this.setProps({ feedRequest: { createPost: RequestState.SUCCESS } });
    } catch (e) {
      this.errorLogger.trace(e);
      this.setProps({ feedRequest: { createPost: RequestState.ERROR } });
      console.log('[FeedController.createFeedItem]: Error', e);
    } finally {
    }
  };

  createPostTopic = (name: string) => {
    return this.feedStore.createTopic(name);
  };

  setProps(props: Partial<FeedControllerProps>) {
    return this.controllerConfig.setProps(props);
  }

  pageReady = async () => {
    this.setProps(this.getActions());

    const groupId = await this.getGroupId();

    if (this.experimentEnabled('specs.groups.Realtime')) {
      this.installDuplexer(groupId!);
      this.feedStore.connectChannel(this.feedChannel);
    }

    autorun(async () => {
      const store = serialize(this.feedStore) as FeedStore;
      this.setProps({
        feedLoading: store.loading,
        cursor: store.cursor,
        prevCursor: store.prevCursor,
        feedItems: store.feedItems,
        feedTopics: store.topics,
        feedFilters: this.feedFilters,
      });
    });
  };

  updateContextToken() {
    this.feedRepository.feed
      .getContextToken()
      .then((contextToken) => this.setProps({ contextToken }))
      .then((_) => this.publishDidUpdateToken());
  }

  async getFeedProps(): Promise<Partial<FeedControllerProps>> {
    try {
      await this.initFeed();
    } catch (e: any) {
      const statusText = 'FeedController.getInitialProps: FAILED in fetch feed';
      console.log(statusText);
      this.errorLogger.log(e);
      throw new ControllerError(
        ErrorOrigin.Feed,
        e.response || { status: 0, statusText },
      );
    }

    const store = serialize(this.feedStore) as FeedStore;
    const { cursor, loading, feedItems, topics, prevCursor } = store;
    return {
      feedLoading: loading,
      cursor,
      prevCursor,
      feedItems,
      feedTopics: topics,
      feedFilters: await this.getFilters(),
      contextToken: await this.feedRepository.feed.getContextToken(),
    };
  }

  async getInitialProps(): Promise<Partial<FeedControllerProps>> {
    if (this.isSSR() && !this.isInSEO()) {
      return {
        feedLoading: true,
        cursor: null,
        feedItems: [],
        feedTopics: [],
        feedFilters: {},
        contextToken: null,
      };
    }
    return this.getFeedProps();
  }

  onBeforeUnLoad() {
    this.removeSubscriptions();
  }

  removeSubscriptions() {
    // https://wix.slack.com/archives/CAKBA7TDH/p1601480311035300
    // TODO: remove after fixed on TB side
    try {
      const storage = this.controllerConfig.platformAPIs.storage.session;
      const key = 'FeedController.setSubscriptions';
      const subscr = JSON.parse(storage.getItem(key)!);
      if (!subscr) {
        return;
      }
      for (const [id, event] of Object.entries(subscr) as any) {
        this.controllerConfig.platformAPIs.pubSub.unsubscribe(event, id);
      }
      storage.removeItem(key);
      console.log('FeedController.removeSubscriptions', storage.getItem(key));
    } catch (e) {
      console.log('Error in FeedController.removeSubscriptions');
    }
    //
    try {
      this.subscriptions.forEach((event, subscrId) => {
        this.controllerConfig.platformAPIs.pubSub.unsubscribe(event, subscrId);
      });
    } catch (e) {
      ConsoleLogger.log(e);
    } finally {
      this.subscriptions.clear();
    }
    console.log('FeedController.removeSubscriptions', this.subscriptions.size);
  }

  setSubscriptions() {
    try {
      this.subscribe(
        PubSubEventTypes.DID_UPDATE_GROUP_EVENT,
        this.onDidUpdateGroup,
      );
      this.subscribe<LeaveRequest>(PubSubEventTypes.JOIN_GROUP, ({ data }) => {
        return this.handleUserLogin();
      });
      this.subscribe<LeaveRequest>(PubSubEventTypes.LEAVE_GROUP, ({ data }) => {
        return this.handleUserLogin(); // TODO?
      });
      this.subscribe(
        PubSubEventTypes.COMMENTS_CHANGED,
        this.updateComments,
        false,
      );
    } catch (e) {
      ConsoleLogger.log(e);
      this.errorLogger.log(e);
    }
  }

  private publishDidUpdateToken() {
    try {
      this.controllerConfig.platformAPIs.pubSub.publish(
        PubSubEventTypes.DID_UPDATE_CONTEXT_TOKENS_EVENT,
        null,
        false,
      );
    } catch (e) {
      ConsoleLogger.log(e);
      this.errorLogger.log(e);
    }
  }

  private readonly onDidUpdateGroup = async ({ data }: any) => {
    try {
      const updatedGroup = JSON.parse(data);
      this.group = updatedGroup;
    } catch (e) {
      console.log('Failed in DID_UPDATE_GROUP_EVENT');
    }
  };

  applyFilters = (filters = {}) => {
    const location = this.getLocation();
    const query = toQueryParams({
      ...location.query,
      ...filters,
    });
    this.getGroupUrl({ groupId: this.group.slug!, tabName: Tab.DISCUSSION })
      .then((url) => {
        location.to!(`${url}${query}`);
      })
      .catch((e) => {
        console.log('[GroupController.changeTab] Failed', e);
        this.errorLogger.log(e);
      });
  };

  protected async getFilters(): Promise<Partial<Filters>> {
    const location = this.getLocation();
    const { feedItemId } = await this.getUrlSegments();

    const query = {
      feedItemId,
      ...location.query,
    };

    return pickBy(pick(query, FILTER_KEYS_WHITELIST), identity) || {};
  }

  protected async resolveRoute(tab: Tab = Tab.DISCUSSION): Promise<void> {
    if (!canSeeFeed(this.group) || tab !== Tab.DISCUSSION) {
      return;
    }
    return this.fetchDiscussions();
  }

  protected async fetchDiscussions() {
    await this.feedStore.fetchTopics();
    const cursor = this.getCursorFromLocation();
    if (cursor) {
      this.feedStore.setCursor(cursor);
      return this.fetchMore();
    }

    this.feedFilters = await this.getFilters();
    if (Object.keys(this.feedFilters).length) {
      await this.feedStore.filter(this.feedFilters);
    } else {
      const limit = this.getPostsLimit();
      await this.feedStore.fetch(limit);
    }
  }

  private getPostsLimit() {
    return this.isInSEO() ? DEFAULT_FEED_LIMIT : FIRST_LOAD_FEED_ITEMS_COUNT;
  }

  private readonly handleLocationChange = async (data: { path: string[] }) => {
    const [, , activeTab] = data.path;

    const filters = await this.getFilters();

    if (JSON.stringify(this.feedFilters) === JSON.stringify(filters)) {
      return;
    }

    this.resolveRoute(activeTab as Tab).catch((e) => {
      console.log('Error in FeedController.handleLocationChange', e);
    });
  };

  private readonly fetchMore = () => {
    try {
      this.feedStore.fetchMore();
    } catch (e) {
      console.log('[FeedController.fetchMore]: Error');
      this.errorLogger.trace(e);
    }
  };

  private readonly updateFeedItem = (
    feedItemId: string,
    entity: IFeedTypes.FeedItemEntity,
  ) => {
    try {
      this.feedStore.update(feedItemId, entity);
    } catch (e) {
      console.log('[FeedController.updateFeedItem]: Error');
      this.errorLogger.trace(e);
    }
  };

  private readonly deleteFeedItem = async (feedItemId: string) => {
    try {
      await this.feedStore.delete(feedItemId);
    } catch (e) {
      console.log('[FeedController.deleteFeedItem]: Error');
      this.errorLogger.trace(e);
    }
  };

  private updateComments = (commentsChangedEvent: {
    data: PaginationState;
  }) => {
    this.feedStore.updateComments(commentsChangedEvent.data);
  };

  private getCursorFromLocation() {
    const loc = this.getLocation();
    return loc.query.cursor;
  }
}
